import { Percent } from '@uniswap/sdk-core'
import { ComponentProps } from 'react'
import { InterfaceTrade } from 'state/routing/types'
import styled from 'styled-components'

import { RowBetween, RowFixed } from '../Row'
import SettingsTab from '../Settings'
const Refresh = (props: ComponentProps<'svg'>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs>
      <linearGradient id="grd-svg">
        <stop className="stop1" offset="0%"></stop>
        <stop className="stop2" offset="100%"></stop>
      </linearGradient>
    </defs>
    <g id="i-refresh">
      <path
        fill="url(#grd-svg)"
        clipRule="evenodd"
        d="M4.39961 12.5C4.39961 8.30261 7.80225 4.89998 11.9996 4.89998C14.5562 4.89998 16.8192 6.16236 18.1973 8.09998H16.0004C15.5033 8.09998 15.1004 8.50292 15.1004 8.99998C15.1004 9.49703 15.5033 9.89998 16.0004 9.89998H19.7285C19.7417 9.90027 19.755 9.90027 19.7683 9.89998H20.5004C20.9974 9.89998 21.4004 9.49703 21.4004 8.99998V4.49998C21.4004 4.00292 20.9974 3.59998 20.5004 3.59998C20.0033 3.59998 19.6004 4.00292 19.6004 4.49998V6.9685C17.8918 4.62463 15.1244 3.09998 11.9996 3.09998C6.80813 3.09998 2.59961 7.3085 2.59961 12.5C2.59961 17.6915 6.80813 21.9 11.9996 21.9C16.3913 21.9 20.0775 18.8891 21.1108 14.8203C21.2332 14.3385 20.9418 13.8488 20.4601 13.7264C19.9783 13.6041 19.4886 13.8954 19.3662 14.3772C18.5307 17.6672 15.5481 20.1 11.9996 20.1C7.80225 20.1 4.39961 16.6973 4.39961 12.5Z"
      ></path>{' '}
    </g>
    <use xlinkHref="#i-refresh"></use>
  </svg>
)

const StyledSwapHeader = styled(RowBetween)`
  margin-bottom: 10px;
  color: ${({ theme }) => theme.neutral2};
`

const HeaderButtonContainer = styled(RowFixed)`
  padding: 0 12px;
  gap: 16px;
`
export default function SwapHeader({
  autoSlippage,
  chainId,
  trade,
}: {
  autoSlippage: Percent
  chainId?: number
  trade?: InterfaceTrade
}) {
  return (
    <StyledSwapHeader>
      <div className="swap-tabs-container">
        <div className="swap-tab active">Swap</div>
        <div className="swap-tab disabled">Limit</div>
        <div className="swap-tab disabled">Bridge</div>
      </div>
      <RowFixed>
        <Refresh className="icon" />

        <SettingsTab autoSlippage={autoSlippage} chainId={chainId} trade={trade} />
      </RowFixed>
    </StyledSwapHeader>
  )
}
