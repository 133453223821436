import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import React from 'react'

import ERC20ABI from './abis/erc20.json'

export const BlockchainContext = React.createContext({
  contractAddress: '',
  setContractAddress: (address) => {},
  symbol: '',
  balance: 0,
  targetSymbol: '',
  tier: '',
  tokenContract: {},
  provider: {},
})
BlockchainContext.propTypes = {
  children: PropTypes.node.isRequired,
}

export const BlockchainProvider = ({ children }) => {
  const [contractAddress, setContractAddress] = useState('0xce3ee7435a5bEdBE73b92f39828b0CFD9D0FF568')
  const { account, provider } = useWeb3React()
  const [balance, setBalance] = useState(0)
  const [symbol, setSymbol] = useState('')
  const [targetSymbol, setTargetSymbol] = useState('')
  const [tier, setTier] = useState('')
  useEffect(() => {
    if (provider) {
      provider.getNetwork().then((network) => {
        if (network.chainId !== 1) {
          console.log(network.chainId)
          console.error('Please connect to the Ethereum network')
          return
        }
      })
    }
  }, [provider])
  const tokenAddress = '0xce3ee7435a5bEdBE73b92f39828b0CFD9D0FF568'
  const tokenContract = new ethers.Contract(tokenAddress, ERC20ABI, provider)
  async function getDecimals() {
    const decimals = await tokenContract.decimals()
    console.log(decimals)
    return decimals
  }
  async function getBalance() {
    const decimals = await tokenContract.decimals()
    const balance = await tokenContract.balanceOf(account)
    console.log(balance)
    let formattedBalance = ethers.utils.formatUnits(balance, decimals)

    formattedBalance = parseFloat(formattedBalance).toFixed(2)
    formattedBalance = parseFloat(formattedBalance)
    formattedBalance = Number(formattedBalance)
    await getTier(formattedBalance)
    setBalance(formattedBalance)
  }
  async function getSymbol() {
    const symbol = await tokenContract.symbol()
    console.log(symbol)
    setSymbol(symbol)
  }
  async function getTier(balance) {
    const balanceNumber = Number(balance)
    console.log(balanceNumber)
    let newTier = ''
    if (balanceNumber <= 4999) {
      newTier = 'Bronze'
    } else if (balanceNumber <= 5000) {
      newTier = 'Silver'
    } else if (balanceNumber <= 10000) {
      newTier = 'Gold'
    }
    console.log('tier', newTier)
    setTier(newTier)
  }

  async function init() {
    if (!provider || !account) return
    await getDecimals()
    await getSymbol()
    await getBalance()
  }

  useEffect(() => {
    init()
    console.log(balance + ' balance')
    console.log(symbol + ' symbol')
    console.log(tier + ' tier')
  }, [contractAddress, provider, account])

  useEffect(() => {
    getTargetSymbol()
  }, [contractAddress])

  async function getTargetSymbol() {
    if (!contractAddress) return
    try {
      const tokenContract = new ethers.Contract(contractAddress, ERC20ABI, provider)
      const targetSymbol = await tokenContract.symbol()
      console.log(targetSymbol)
      setTargetSymbol(targetSymbol)
    } catch (e) {
      setTargetSymbol('Select a token')
    }
  }

  return (
    <BlockchainContext.Provider
      value={{
        tier,
        targetSymbol,
        setContractAddress,
        contractAddress,
        symbol,
        balance,
        provider,
      }}
    >
      {children}
    </BlockchainContext.Provider>
  )
}
