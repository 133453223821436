import React from 'react'
const originalConsoleWarn = console.warn
const originalConsoleLog = console.log

console.warn = function (...args) {
  const message = args[0]
  if (
    typeof message === 'string' &&
    message.includes('Access to XMLHttpRequest') &&
    message.includes('has been blocked by CORS policy')
  ) {
    return
  }
  originalConsoleWarn.apply(console, args)
}
window.onerror = function (message, source, lineno, colno, error) {
  if (message.includes('Access to XMLHttpRequest') && message.includes('has been blocked by CORS policy')) {
    // Suppress the CORS error
    return true // Returning true prevents the error from being logged in the console
  }

  // Otherwise, allow the error to be logged normally
  return false
}

console.log = function (...args) {
  const message = args[0]
  if (
    typeof message === 'string' &&
    message.includes('Access to XMLHttpRequest') &&
    message.includes('has been blocked by CORS policy')
  ) {
    return
  }
  originalConsoleLog.apply(console, args)
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <h1>This is an open development build</h1>
          <button onClick={() => window.location.reload()}>Enter App</button>
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
