import tokenLogoLookup from 'constants/tokenLogoLookup'
import { isCelo, nativeOnChain } from 'constants/tokens'
import { checkWarning, WARNING_LEVEL } from 'constants/tokenSafety'
import { chainIdToNetworkName, getNativeLogoURI } from 'lib/hooks/useCurrencyLogoURIs'
import uriToHttp from 'lib/utils/uriToHttp'
import { useCallback, useEffect, useState } from 'react'
import { isAddress } from 'utils'

import celoLogo from '../assets/svg/celo_logo.svg'

const BAD_SRCS: { [tokenAddress: string]: true } = {}

// Converts uri's into fetchable urls
function parseLogoSources(uris: string[]) {
  const urls: string[] = []
  uris.forEach((uri) => urls.push(...uriToHttp(uri)))
  return urls
}

// Parses uri's, favors non-coingecko images, and improves coingecko logo quality
function prioritizeLogoSources(uris: string[]) {
  const parsedUris = uris.map((uri) => uriToHttp(uri)).flat(1)
  const preferredUris: string[] = []

  // Consolidate duplicate coingecko urls into one fallback source
  let coingeckoUrl: string | undefined = undefined

  parsedUris.forEach((uri) => {
    if (uri.startsWith('https://assets.coingecko')) {
      if (!coingeckoUrl) {
        coingeckoUrl = uri.replace(/small|thumb/g, 'large')
      }
    } else {
      preferredUris.push(uri)
    }
  })
  // Places coingecko urls in the back of the source array
  return coingeckoUrl ? [...preferredUris, coingeckoUrl] : preferredUris
}

function getInitialUrl(
  address?: string | null,
  chainId?: number | null,
  isNative?: boolean,
  backupImg?: string | null
) {
  if (chainId && isNative) return getNativeLogoURI(chainId)

  const networkName = chainId ? chainIdToNetworkName(chainId) : 'ethereum'
  const checksummedAddress = isAddress(address)

  if (chainId && isCelo(chainId) && address === nativeOnChain(chainId).wrapped.address) {
    return celoLogo
  }
  const tokens = [
    {
      chainId: 1,
      address: '0x0f7b3f5a8fed821c5eb60049538a548db2d479ce',
      name: 'AirTor Protocol',
      symbol: 'ATOR',
      decimals: 18,
      logoURI:
        'https://www.dextools.io/resources/tokens/logos/ether/0x0f7b3f5a8fed821c5eb60049538a548db2d479ce.png?1678831118286',
    },
    {
      chainId: 1,
      address: '0x7f9b09f4717072cf4dc18b95d1b09e2b30c76790',
      name: 'VaultTech',
      symbol: '$VAULT',
      decimals: 18,
      logoURI:
        'https://www.dextools.io/resources/tokens/logos/ether/0x7f9b09f4717072cf4dc18b95d1b09e2b30c76790.jpeg?1698515624242',
    },
    {
      chainId: 1,
      address: '0x24edded3f03abb2e9d047464294133378bddb596',
      name: 'Sect Bot',
      symbol: 'SECT',
      decimals: 18,
      logoURI:
        'https://www.dextools.io/resources/tokens/logos/ether/0x24edded3f03abb2e9d047464294133378bddb596.png?1701911627047',
    },
    {
      chainId: 1,
      address: '0xce3ee7435a5bEdBE73b92f39828b0CFD9D0FF568',
      name: 'PhenX',
      symbol: 'UDX',
      decimals: 18,
      logoURI: 'https://i.ibb.co/hX9FHL9/Unidex-Ai-Logo.jpg',
    },
    {
      chainId: 1,
      address: '0xf49311af05a4ffb1dbf33d61e9b2d4f0a7d4a71c',
      name: 'CompanionBot',
      symbol: 'CBot',
      decimals: 9,
      logoURI:
        'https://www.dextools.io/resources/tokens/logos/ether/0xf49311af05a4ffb1dbf33d61e9b2d4f0a7d4a71c.png?1690364311762',
    },
    {
      chainId: 1,
      address: '0xd166b7d9824cc5359360b47389aba9341ce12619',
      name: 'PhenX',
      symbol: 'PNX',
      decimals: 9,
      logoURI: 'https://i.ibb.co/ZB2jF4D/phenxlogo.png',
    },
    {
      chainId: 1,
      address: '0x49F8A29217df7791d3F226200014ad725122A4D4',
      name: 'Sometimes Alone',
      symbol: 'ALONE',
      decimals: 18,
      logoURI: 'https://i.ibb.co/whhBtdb/photo-2023-12-12-18-21-18.png',
    },
    {
      chainId: 1,
      address: '0x113C65707C530502FEf959308197353f6DF97867',
      name: 'The Joker Coin',
      symbol: 'JOKER',
      decimals: 18,
      logoURI:
        'https://www.dextools.io/resources/tokens/logos/ether/0x113c65707c530502fef959308197353f6df97867.png?1684336373730',
    },
    {
      chainId: 1,
      address: '0x5da151b95657e788076d04d56234bd93e409cb09',
      name: 'OTSea',
      symbol: 'OTSea',
      decimals: 18,
      logoURI:
        'https://www.dextools.io/resources/tokens/logos/ether/0x5da151b95657e788076d04d56234bd93e409cb09.png?1706873053022',
    },
    {
      chainId: 1,
      address: '0x5da151b95657e788076d04d56234bd93e409cb09',
      name: 'StudioAi',
      symbol: 'SAI',
      decimals: 9,
      logoURI:
        'https://www.dextools.io/resources/tokens/logos/ether/0xeac32927cf2785cbe0d515298cab448538a8cdd7.png?1704888781909',
    },
    {
      chainId: 1,
      address: '0xa9fbcc25435ad713a9468d8c89dd7baae8914e3a',
      name: 'Prophet',
      symbol: 'PROPHET',
      decimals: 18,
      logoURI:
        'https://www.dextools.io/resources/tokens/logos/ether/0xa9fbcc25435ad713a9468d8c89dd7baae8914e3a.jpeg?1695037825204',
    },
    {
      chainId: 1,
      address: '0xf5aed4f6a1ad00f39dd21febb6f400ea020030c2',
      name: 'Hodless BOT',
      symbol: 'HBOT',
      decimals: 18,
      logoURI:
        'https://www.dextools.io/resources/tokens/logos/ether/0xf5aed4f6a1ad00f39dd21febb6f400ea020030c2.png?1698001027069',
    },
    {
      chainId: 1,
      address: '0xa9fbcc25435ad713a9468d8c89dd7baae8914e3a',
      name: 'Shill Guard Token',
      symbol: 'SGT',
      decimals: 18,
      logoURI:
        'https://www.dextools.io/resources/tokens/logos/ether/0xa0e7626287bd02cbe3531c65148261bf0c0ed98b.png?1699350137877',
    },
    {
      chainId: 1,
      address: '0xf250b1f6193941bb8bff4152d719edf1a59c0e69',
      name: 'AIREALM',
      symbol: 'AIRM',
      decimals: 18,
      logoURI:
        'https://www.dextools.io/resources/tokens/logos/ether/0xf250b1f6193941bb8bff4152d719edf1a59c0e69.png?1705078677233',
    },
    {
      chainId: 1,
      address: '0x67268687e26d0f34cac5de30e6c2f63facb592bd',
      name: 'All-In-One',
      symbol: 'AI1',
      decimals: 9,
      logoURI:
        'https://www.dextools.io/resources/tokens/logos/ether/0x67268687e26d0f34cac5de30e6c2f63facb592bd.jpg?1707422451595',
    },
  ]

  function getLogoUrl(address: string): string | undefined {
    const token = tokens.find((token) => token.address.toLowerCase() === address.toLowerCase())
    return token ? token.logoURI : undefined
  }
  if (address == undefined) return undefined
  const logoUrl = getLogoUrl(address)
  if (logoUrl) {
    return logoUrl
  }

  if (checksummedAddress) {
    return `https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/${networkName}/assets/${checksummedAddress}/logo.png`
  } else {
    return backupImg ?? undefined
  }
}

export default function useAssetLogoSource(
  address?: string | null,
  chainId?: number | null,
  isNative?: boolean,
  backupImg?: string | null
): [string | undefined, () => void] {
  const hideLogo = Boolean(address && checkWarning(address, chainId)?.level === WARNING_LEVEL.BLOCKED)
  const [current, setCurrent] = useState<string | undefined>(
    hideLogo ? undefined : getInitialUrl(address, chainId, isNative, backupImg)
  )
  const [fallbackSrcs, setFallbackSrcs] = useState<string[] | undefined>(undefined)

  useEffect(() => {
    if (hideLogo) return
    setCurrent(getInitialUrl(address, chainId, isNative))
    setFallbackSrcs(undefined)
  }, [address, chainId, hideLogo, isNative])

  const nextSrc = useCallback(() => {
    if (current) {
      BAD_SRCS[current] = true
    }
    // Parses and stores logo sources from tokenlists if assets repo url fails
    if (!fallbackSrcs) {
      const uris = tokenLogoLookup.getIcons(address, chainId) ?? []
      if (backupImg) uris.push(backupImg)
      const tokenListIcons = prioritizeLogoSources(parseLogoSources(uris))

      setCurrent(tokenListIcons.find((src) => !BAD_SRCS[src]))
      setFallbackSrcs(tokenListIcons)
    } else {
      setCurrent(fallbackSrcs.find((src) => !BAD_SRCS[src]))
    }
  }, [current, fallbackSrcs, address, chainId, backupImg])

  return [current, nextSrc]
}
