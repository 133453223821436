export const Telegram = () => (
  <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient id="grd-svg">
        <stop className="stop1" offset="0%"></stop>
        <stop className="stop2" offset="100%"></stop>
      </linearGradient>
    </defs>
    <g id="i-telegram">
      <path
        fill="url(#grd-svg)"
        d="M2.617 15.832l6.44 2.187 15.3-9.348c.222-.136.449.165.258.341L13.03 19.668l-.43 5.965c-.033.454.514.706.838.387l3.564-3.505 6.516 4.932a1.21 1.21 0 0 0 1.909-.703l4.537-20.6c.259-1.175-.893-2.167-2.016-1.736L2.585 14.12c-.796.305-.774 1.438.033 1.712z"
      ></path>
    </g>
    <use xlinkHref="#i-telegram"></use>
  </svg>
)
export const Twitter = () => (
  <svg viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient id="grd-svg">
        <stop className="stop1" offset="0%"></stop>
        <stop className="stop2" offset="100%"></stop>
      </linearGradient>
    </defs>
    <defs>
      <g id="i-x">
        <path
          fill="url(#grd-svg)"
          d="M18.205 2.25h3.308l-7.227 8.26l8.502 11.24H16.13l-5.214-6.817L4.95 21.75H1.64l7.73-8.835L1.215 2.25H8.04l4.713 6.231zm-1.161 17.52h1.833L7.045 4.126H5.078z"
        ></path>
      </g>
    </defs>
    <use xlinkHref="#i-x"></use>
  </svg>
)

export const Medium = () => (
  <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient id="grd-svg">
        <stop className="stop1" offset="0%"></stop>
        <stop className="stop2" offset="100%"></stop>
      </linearGradient>
    </defs>
    <defs>
      <g id="i-medium">
        <path
          fill="url(#grd-svg)"
          d="M285.6 256c0 72.3-58.3 131-130.1 131S25.3 328.3 25.3 256s58.3-131 130.1-131c71.9 0 130.2 58.7 130.2 131m142.7 0c0 68.1-29.1 123.3-65.1 123.3s-65.1-55.2-65.1-123.3 29.1-123.3 65.1-123.3 65.1 55.2 65.1 123.3m58.4 0c0 61-10.2 110.5-22.9 110.5-12.6 0-22.9-49.5-22.9-110.5s10.2-110.5 22.9-110.5c12.6 0 22.9 49.5 22.9 110.5"
        ></path>
      </g>
    </defs>
    <use xlinkHref="#i-medium"></use>
  </svg>
)
function FooterBar() {
  return (
    <div className="footer-container">
      <div className="nav-left">
        <div className="icons-container">
          {' '}
          <a href="https://t.me/PhenXdex" title="Telegram" target="_blank" rel="noopener noreferrer" className="icon">
            {' '}
            <Telegram />
          </a>{' '}
          <a
            href="https://twitter.com/PhenXdex"
            title="Twitter"
            target="_blank"
            rel="noopener noreferrer"
            className="icon"
          >
            {' '}
            <Twitter />
          </a>{' '}
          <a
            href="https://twitter.com/PhenXdex"
            title="Medium"
            target="_blank"
            rel="noopener noreferrer"
            className="icon"
          >
            {' '}
            <Medium />
          </a>
        </div>
      </div>
      <div className="nav-right">
        {' '}
        <a
          href="https://t.me/PhenXdex"
          style={{
            color: 'white',
            fontSize: '14px',
            textAlign: 'center',
            letterSpacing: '7px',
          }}
        >
          TALK TO US
        </a>
      </div>
    </div>
  )
}
export default FooterBar
